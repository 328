.error-page-container {
    padding: 5rem 2rem;

    .error-message-header {
        align-items: center;
        display: flex;
        margin-bottom: 2rem;

        .error-icon {
            margin-right: 1rem;
        }

        .error-message-sorry {
            color: $error-red;
        }
    }

    @media screen and (min-width: $desktop) {
        padding: 5rem 3rem;
    }
}

.accordion-details {
    flexDirection: "column";
}

// Styles for dms error pages
.dms-error-container {
    padding: 0.625rem 1.5rem;

    .paper {
        border-radius: 0;
        margin-bottom: 0.625rem;
        padding: 1.125rem 1rem 1.125rem 1.25rem;
    }

    .review-unsubmitted-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto auto;
    }

    // Unsubmitted appointment date-time
    .review-unsubmitted-appointment-date-time-section {
        .review-unsubmitted-appointment-date-time {
            font-size: 1.5rem;
            padding-bottom: 0.75rem;
        }
    }

    // Unsubmitted appointment vehicle information
    .review-unsubmitted-vehicle-info-section {
        .review-unsubmitted-vehicle-info-title {
            font-size: 1.5rem;
            padding-bottom: 0.75rem;
        }
    }

    // Unsubmitted appointment services
    .review-confirm-services-title-container{
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0.625rem;
    }

    .review-confirm-services-list {
        .service-item {
            color: $body1-font-color;
            padding-bottom: 0.75rem;

            &:not(:nth-last-of-type(-n + 2)) {
                border-bottom: $border-table;
                margin-bottom: 0.75rem;
                padding-bottom: 0.75rem;
            }

            &.review-confirm-services-comments {
                &:not(:empty) {
                    border-top: $border-table;
                    margin-top: 0.75rem;
                    overflow-wrap: anywhere;
                    padding-top: 0.75rem;
                }
            }

            .service-offer,
            .service-price {
                margin-left: 1rem;
            }

            .service-title {
                @extend %bold;

                color: $body1-font-color;
            }

            .service-offer {
                font-size: $body2-font-size;
            }

            .service-price {
                display: flex;
                margin-top: 0.5rem;

                &-value:not(:empty) {
                    margin-right: 0.3em;
                }
            }
        }
    }

    .review-confirm-services-info {
        &.paper {
            padding: 1.125rem 0 0;
        }

        .review-confirm-services-content {
            padding: 0 1rem 0 1.25rem;
        }

        .check-box-wrapper {
            align-items: center;
            display: flex;
            padding: 1.125rem 0;

            &.silver {
                border-left: 4px solid $fill-tier-silver;
            }

            &.gold {
                border-left: 4px solid $fill-tier-gold;
            }

            &.platinum {
                border-left: 4px solid $fill-tier-platinum;
            }

            &.gray {
                background-color: $light-grey-f5;
            }

            .checkbox-container {
                margin-left: 0.625rem;

                &:hover,
                &:focus {
                    background-color: transparent;
                }
            }

            .check-box-text {
                margin-left: 1rem;
            }
        }
    }

    .review-confirm-services-edit {
        text-align: center;
    }
}